/*==========================
	Custom css
==========================*/
/* Header */
.fullwidth .logo-header {
  margin-right: 30px;
}

.fullwidth .header-nav .nav > li {
  padding: 20px 0;
  margin: 0px 3px;
}

.fullwidth .header-nav .nav > li.active > a,
.fullwidth .header-nav .nav > li:hover > a {
  background-color: #62bd5e !important;
  color: #fff;
}

.about-img-content .about-img-1 .about-img-2 {
  text-align: right;
  margin-top: -125px;
}

.about-content {
  margin-left: 40px;
  margin-top: -5px;
}

.extra-nav {
  padding: 20px 0;
}

.extra-nav .site-button {
  margin-left: 10px;
}

.fullwidth .header-nav .nav > li > a {
  padding: 10px 15px;
  border-radius: 4px;
}

.fullwidth .is-fixed .header-nav .nav > li {
  padding: 13px 0;
}

.fullwidth .is-fixed .extra-nav {
  padding: 14px 0;
}

.fullwidth .is-fixed .header-nav .nav > li:hover > a,
.fullwidth .is-fixed .header-nav .nav > li.active > a {
  color: #fff;
}

.fullwidth .is-fixed .header-nav .nav > li > a {
  color: #222845;
}

/* Job Search Tabs */
.job-search-tabs .dez-tabs .nav-tabs {
  border-bottom: 0;
}

.job-search-tabs .dez-tabs .nav-tabs > li > a {
  border-radius: 10px 10px 0 0;
  padding: 15px 30px;
  border: 0;
  color: #000;
  font-weight: 700;
  background-color: #fff;
  font-size: 14px;
  margin-bottom: 0;
}

.job-search-tabs .dez-tabs .nav-tabs li a:hover,
.job-search-tabs .dez-tabs .nav-tabs li a:active,
.job-search-tabs .dez-tabs .nav-tabs li a:focus,
.job-search-tabs .dez-tabs .nav-tabs li a.show,
.job-search-tabs .nav-tabs li a.show:focus,
.job-search-tabs .nav-tabs li a.show:hover,
.job-search-tabs .nav-tabs li a.show {
  padding: 15px 30px;
  background-color: #ff2a2a;
  font-weight: 700;
  color: #fff;
  border-bottom: 0;
}

.job-search-tabs .form-control,
.job-search-tabs .bootstrap-select .dropdown-toggle {
  border: 2px solid #eeeeee !important;
  font-size: 14px;
  font-family: Montserrat;
  border-radius: 4px;
  font-weight: 400;
  height: 50px;
  padding: 10px 20px;
  color: #606060;
}

.job-search-tabs .form-control::-moz-placeholder {
  color: #606060;
}

.job-search-tabs .site-button {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  padding: 13px 20px;
}

/* Job Search Tabs End */
.testimonial-text p {
  font-style: italic;
  font-size: 15px;
  color: #4a4a4a;
  line-height: 28px;
}

/* Job Search */
.other-job-list h3 {
  margin-bottom: 10px;
}

.other-job-list .job-box-list {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 30px 40px;
  background: #fff;
  margin-bottom: 10px;
  transition: 0.3s;
}

.info-box {
  padding: 50px;
}

.job-info {
  margin-bottom: 0;
}

.job-info li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}

.job-info li strong {
  font-weight: 600;
  color: #000;
}

/* Job Categories */
.job-categories .icon-bx-wraper {
  padding: 40px 10px;
  margin-bottom: 20px;
  background: #2e55fa;
  border-radius: 10px;
  text-align: center;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  overflow: hidden;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
}

.job-categories .icon-bx-wraper .dez-tilte {
  display: block;
  width: 100%;
  color: #000;
  font-size: 18px;
}

.job-categories .icon-bx-wraper .icon-content {
  position: relative;
  z-index: 1;
}

.job-categories .icon-bx-wraper .rotate-icon {
  position: absolute;
  font-size: 90px;
  color: #000;
  bottom: 10px;
  line-height: 90px;
  right: 20px;
  opacity: 0.05;
  text-align: right;
}

.job-categories .icon-bx-wraper:after {
  background-image: url(../images/categories-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  content: "";
  width: 100%;
  height: 110px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.job-categories .icon-bx-wraper:hover:after {
  height: 0;
}

.job-categories .icon-bx-wraper:hover {
  background-color: #2e55fa;
  transform: scale(1.05);
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
}

.job-categories .icon-bx-wraper .rotate-icon,
.job-categories .icon-bx-wraper .dez-tilte,
.job-categories .icon-bx-wraper {
  color: #fff;
}

.job-categories .icon-bx-wraper:hover .icon-md i {
  color: #fff;
}

/* Head Counter */
.head-counter h6 {
  color: #494949;
}

.head-counter-bx {
  margin-left: 30px;
}

@media only screen and (max-width: 991px) {
  .fullwidth .header-nav .nav > li {
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .other-job-list > li {
    padding: 30px 20px;
  }

  .app-info {
    text-align: center;
  }
}

@media only screen and (max-width: 575px) {
  .dez-bnr-inr .nav li a,
  .dez-bnr-inr .nav li a:hover,
  .dez-bnr-inr .nav li a:active,
  .dez-bnr-inr .nav li a:focus,
  .dez-bnr-inr .nav li a.show,
  .tabs-site-button .nav-tabs li a.show:focus,
  .tabs-site-button .nav-tabs li a.show:hover,
  .tabs-site-button .nav-tabs li a.show {
    padding: 10px 15px;
    font-size: 12px;
  }

  .job-search-tabs .dez-tabs .nav-tabs > li > a:active,
  .job-search-tabs .dez-tabs .nav-tabs > li > a:focus,
  .job-search-tabs .dez-tabs .nav-tabs > li > a:hover,
  .job-search-tabs .dez-tabs .nav-tabs > li > a,
  .job-search-tabs .dez-tabs .nav-tabs > li > a.show {
    padding: 15px 20px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 360px) {
  .dez-bnr-inr-entry h2 {
    font-size: 35px;
    line-height: 40px;
  }

  .job-info li {
    padding: 4px 0;
  }

  .other-job-list img {
    text-align: center;
    margin-top: 20px;
  }

  .info-box {
    padding: 30px;
  }
}

.about-timeline::after {
  background-color: #ff2a2a;
}

/* post-job */
.post-job .dropdown-toggle {
  height: 50px;
  border-radius: 0;
  border: 2px solid #ededed !important;
}

.other-job-list.style-2 .job-box-list {
  margin-bottom: -1px;
  border: 1px solid #ededed;
  padding: 20px;
}

.other-job-list.style-2 .title-head {
  font-size: 20px;
}

.b-job-info li {
  list-style: none;
  float: left;
  border: 1px solid #d2d2d2;
  border-width: 1px 0;
  padding: 10px 0;
  margin-bottom: 15px;
  width: 33.33%;
}

.b-job-info .img img {
  width: 60px;
  border-radius: 50px;
  float: left;
  margin-right: 25px;
}

.other-job-list.style-2 .job-company-logo {
  width: 100px;
}

.blog-carousel .blog-post {
  margin-bottom: 0;
}

/* error page 404 */
.error-page .dz_error {
  font-size: 200px;
  line-height: 180px;
  margin-bottom: 0;
}

.error-page h3 {
  font-size: 90px;
  letter-spacing: 20px;
  line-height: 70px;
}

.error-page h4 {
  font-size: 45px;
  letter-spacing: 2px;
  line-height: 45px;
  margin-bottom: 30px;
}

.portfolio-box .dez-gallery-box {
  margin-bottom: 10px;
}

.portfolio-box .overlay-icon a {
  padding: 0;
  background: #fff;
  font-size: 15px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 30px;
}

/* Theme BTN */
.site-button-secondry:after,
.site-button:after {
  background-color: #fff;
  content: "";
  height: 100%;
  left: -110%;
  opacity: 0.3;
  position: absolute;
  top: 0;
  transform: skewX(25deg);
  -o-transform: skewX(25deg);
  -ms-transform: skewX(25deg);
  -webkit-transform: skewX(25deg);
  -ms-transform: skewX(25deg);
  transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  width: 25px;
  z-index: -1;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.site-button:hover:after,
.site-button-secondry:hover:after {
  left: 110%;
}

.site-button-secondry:after {
  background-color: #fff;
}

.site-button,
.site-button-secondry {
  position: relative;
  z-index: 1;
  overflow: hidden;
  vertical-align: middle;
  font-family: roboto;
}

/* Gallery */
.gallery-box {
  padding: 150px 0;
}

.gallery-box ul {
  margin: 30px 0 0 0;
  padding: 0;
  list-style: none;
}

.gallery-box ul li {
  display: inline-block;
}

.gallery-box ul li a {
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #ffffff;
  display: block;
  font-size: 18px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  width: 40px;
}

/* testimonial-5 */
/* blog-style-1 */
.blog-style-1 .dez-post-meta {
  margin-bottom: 10px;
  padding: 25px 5px 0;
}

.blog-style-1 .dez-post-title .post-title {
  margin-top: 0;
}

.blog-md .dez-post-meta {
  padding: 0 5px 0;
}

/*  */
.job-search-form h2 {
  font-size: 50px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 50px;
}

.job-search-form h3 {
  font-size: 24px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 30px;
}

@media screen and (max-width: 991px) {
  .error-page .dz_error {
    font-size: 120px;
    line-height: 120px;
  }

  .error-page h3 {
    font-size: 60px;
    letter-spacing: 10px;
    line-height: 40px;
  }

  .error-page h4 {
    font-size: 32px;
    letter-spacing: 0;
    line-height: 26px;
  }

  .counter-style-1 .counter,
  .counter-style-2 .counter,
  .counter-style-3 .counter {
    font-size: 30px;
  }

  .counter-style-1 .icon {
    font-size: 25px;
    margin-right: 5px;
  }
}

.job-search-form .form-control {
  height: 60px;
  border-radius: 0 !important;
  margin-right: 0;
}

.job-search-form .form-control::-moz-placeholder,
.browse-job .form-control,
.submit-resume .form-control {
  font-size: 16px;
  font-family: roboto;
}

.job-search-form .input-group-prepend .site-button {
  height: 60px;
  border-radius: 0;
  padding: 0 30px;
  font-size: 18px;
}

.job-search-form form {
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  margin-bottom: 20px;
}

/* Job List */
.featurd-job-list .job-company-logo {
  width: 120px;
  float: left;
  padding: 5px;
  text-align: center;
  height: 100%;
}

.featurd-job-list .job-company-logo img {
  width: 70%;
}

.featurd-job-list .job-title,
.featurd-job-list .job-info-box {
  width: 50%;
  float: left;
}

.featurd-job-list .job-info-box {
  align-self: center;
}

.featurd-job-list .title-head {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}

.featurd-job-list .job-box-list {
  clear: both;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: -webkit-flex;
  padding: 15px;
  transition: all 0.5s;
  border-left: 2px solid rgba(0, 0, 0, 0);
  background: #f6f6f6;
  border-radius: 5px;
  margin-bottom: 10px;
}

.featurd-job-list .job-info-box {
  text-align: right;
}

.featurd-job-list .job-box-list:hover {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
}

/* testimonial-5 */
.testimonial-5 {
  text-align: center;
}

.testimonial-5 .testimonial-text {
  border-radius: 10px;
  background-color: #fff;
  color: #fff;
  transition: all 0.5s;
}

.active.center .testimonial-5 .testimonial-text {
}

.testimonial-5 .testimonial-text {
  border: 0;
}

.active .testimonial-5 .testimonial-text {
}

.slick-slide.slick-active.slick-current + .slick-active .testimonial-text {
  background-color: #2e55fa;
}

.slick-slide.slick-active.slick-current + .slick-active .testimonial-text p {
  color: #fff;
}

.testimonial-5 .testimonial-detail {
  margin-top: 30px;
}

.slick-slide.slick-active.slick-current + .slick-active .testimonial-text:after,
.testimonial-5 .testimonial-text:after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #fff;
  transform: translate(-50%, -0%);
}

.testimonial-5 .testimonial-detail {
  padding: 0;
}

.slick-slide.slick-active.slick-current
  + .slick-active
  .testimonial-text:after {
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #2e55fa;
}

/* browse-job */
.browse-job .widget-title {
  margin-bottom: 10px;
}

.browse-job .form-control,
.browse-job .btn.dropdown-toggle.btn-default,
.submit-resume .form-control,
.submit-resume .btn.dropdown-toggle.btn-default {
  background-color: #fff !important;
  border: 0 !important;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1) !important;
}

.custom-control-label:before {
  background-color: #e1e7ff;
}

.submit-resume .dropdown-toggle .caret,
.browse-candidates .dropdown-toggle .caret {
  top: 20px !important;
}

.job-bx-title .bootstrap-select div.dropdown-menu ul li a {
  font-size: 12px;
  font-family: roboto;
}

.browse-job .btn.dropdown-toggle.btn-default,
.submit-resume .btn.dropdown-toggle.btn-default {
  height: 50px;
}

.browse-job .form-control {
  height: 50px;
  border-radius: 5px !important;
  margin-right: 25px;
}

.submit-resume .form-control {
  height: 50px;
  border-radius: 5px !important;
}

.submit-resume textarea {
  height: 120px !important;
  padding: 20px;
}

.overlay-logo {
  width: 100px;
  z-index: 99;
}

/* Resume */
.featurd-job-list.all-resume .job-company-logo img {
  width: 100%;
  padding-right: 15px;
}

.featurd-job-list.all-resume .job-title,
.featurd-job-list.all-resume .job-info {
  width: 100%;
  text-align: left;
}

.featurd-job-list.all-resume .job-info {
  margin-bottom: 10px;
}

.featurd-job-list.all-resume .job-info li {
  margin-right: 10px;
}

.featurd-job-list.all-resume .tag a {
  margin-bottom: 5px;
}

/* Find Job Box */
.find-job-bx {
  text-align: left;
}

.find-job-bx h2 {
  font-size: 45px;
  line-height: 60px;
  font-weight: 500;
}

.find-job-bx p {
  margin-bottom: 10px;
}

.find-job-bx form {
  padding: 30px 30px 5px 30px;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 4px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

.find-job-bx .input-group-text,
.find-job-bx .form-control {
  border-radius: 0;
  background-color: transparent;
  border-width: 0 0 2px 0;
  border-color: #2e55fa;
}

.find-job-bx .form-control {
  padding: 0;
  height: 50px;
  color: #000;
  font-size: 16px;
}

.find-job-bx .form-group {
  position: relative;
}

.find-job-bx .form-group label {
  color: #000;
  font-size: 15px;
  position: absolute;
  top: 10px;
  left: 0;
  margin: 0;
  font-family: rubik;
  font-weight: 400;
  transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.find-job-bx .form-group.focused label {
  top: -12px;
  font-size: 12px;
  opacity: 0.4;
}

.find-job-bx .input-group-text {
  padding: 0;
}

.find-job-bx .dezPlaceAni .site-button {
  height: 50px;
}

.find-job-bx .bootstrap-select .dropdown-toggle,
.find-job-bx .bootstrap-select .dropdown-toggle:active,
.find-job-bx .bootstrap-select .dropdown-toggle:focus,
.find-job-bx .bootstrap-select .dropdown-toggle:hover {
  border-width: 0 0 2px 0 !important;
  border-color: #2e55fa !important;
  background-color: transparent !important;
  border-radius: 0;
  height: 50px;
  padding: 0 0 0 0;
  font-family: rubik;
  color: #000;
  font-size: 15px;
}

.find-job-bx .bootstrap-select ul li {
  font-size: 15px !important;
}

.find-job-bx .bootstrap-select.btn-group .dropdown-toggle .caret {
  top: 20px;
  right: 0;
  font-size: 12px;
}

.find-job-bx .bootstrap-select.btn-group .dropdown-toggle .caret::before {
  content: "\f078";
}

.browse-job .bootstrap-select.btn-group .dropdown-toggle .caret {
  top: 15px;
}

.table-job-bx.browse-job .bootstrap-select.btn-group .dropdown-toggle .caret {
  top: 13px;
}

/* Post Job Bx */
.post-job-bx {
  margin: 0;
  padding: 0;
  list-style: none;
}

.post-job-bx,
.post-job-bx li,
.post-job-bx ul,
.cv-manager,
.cv-manager li,
.cv-manager ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.post-job-bx ul li,
.cv-manager ul li {
  display: inline-block;
  margin-right: 10px;
}

.post-job-bx ul li i,
.cv-manager ul li i {
  margin-right: 5px;
}

.cv-manager .job-post-company {
  margin-right: 20px;
}

.post-job-bx .post-bx {
  padding: 25px;
  background-color: #fff;
  border-left: 5px solid #e1e7ff;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
  display: block;
  position: relative;
}

.post-job-bx .post-bx:hover {
  border-color: #2e55fa;
}

.post-job-bx > li,
.cv-manager > li {
  margin-bottom: 30px;
}

.post-job-bx > li:last-child {
  margin-bottom: 0;
}

.post-job-bx > li:nth-of-type(2n + 1) .post-bx {
  background-color: #f9faff;
}

.job-post-company span {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 6px;
  background-color: #e1e7ff;
  text-align: center;
  overflow: hidden;
}

.save-job .job-post-company {
  max-width: 65px;
}

.post-job-bx h4 {
  margin-bottom: 10px;
}

.salary-bx span {
  color: #000;
  font-family: rubik;
  font-size: 22px;
}

.job-time span {
  border-radius: 4px;
  background-color: #e1e7ff;
  display: block;
  font-size: 15px;
  padding: 2px 10px;
  display: inline-block;
  text-align: center;
  transition: all 0.5s;
  font-family: roboto;
  text-transform: capitalize;
}

.job-time a:hover span {
  color: #e1e7ff;
  transition: all 0.5s;
}

/* Candidates Box */
.candidates-are-sys {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
  padding: 30px 20px;
}

.candidates-bx .testimonial-pic.radius {
  width: 75px;
  height: 75px;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
  border: 4px solid #fff;
  margin-bottom: 25px;
}

.candidates-bx .testimonial-text {
  padding: 0;
  color: #000000;
  margin-bottom: 25px;
}

.candidates-bx .testimonial-text p {
  font-style: unset;
  font-size: 16px;
  color: #000;
  line-height: 24px;
  font-weight: 400;
}

.candidates-bx .testimonial-detail {
  padding: 0;
}

.testimonial-name {
  font-family: rubik;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.testimonial-position {
  font-family: rubik;
  font-size: 14px;
  font-style: inherit;
  text-transform: capitalize;
}

.candidates-bx {
  position: relative;
}

.candidates-bx:before,
.candidates-bx:after {
  content: "\f10d";
  font-family: FontAwesome;
  position: absolute;
  color: #eaeefe;
  font-size: 40px;
  top: 80px;
}

.candidates-bx:after {
  content: "\f10e";
  top: auto;
  bottom: -10px;
  right: 0;
}

/* Quote Box */
.quote-bx {
  background-image: url(../images/our-work/pic4.jpg);
  background-size: cover;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
  padding: 30px 20px;
  z-index: 1;
  color: #fff;
}

.quote-bx:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #000930;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 4px;
}

.quote-bx h4 {
  color: #fff;
}

.quote-bx p {
  opacity: 0.9;
}

.quote-info {
  z-index: 2px;
  position: relative;
}

/* City Box */
.city-bx {
  height: 180px;
  border-radius: 4px;
  background-size: cover;
  z-index: 1;
  position: relative;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.city-bx:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #000930;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 4px;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.city-bx:hover:after {
  background-color: #2e55fa;
  opacity: 0.95;
}

.city-info {
  position: relative;
  z-index: 1;
  color: #fff;
  padding: 20px 25px;
}

.city-info h5 {
  font-size: 22px;
  color: #fff;
  margin-bottom: 5px;
}

.city-info span {
  color: #fff;
  opacity: 0.9;
}

.pagination-bx .previous a {
  border-radius: 4px 0px 0px 4px !important;
}

.pagination-bx .next a {
  border-radius: 0px 4px 4px 0px !important;
}

@media only screen and (max-width: 1280px) {
  .fullwidth .header-nav .nav > li > a {
    padding: 10px 8px;
  }

  .job-search-form h2 {
    font-size: 40px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1200px) {
  .extra-nav {
    display: block;
  }

  .header-nav.justify-content-start {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
}

@media only screen and (max-width: 991px) {
  .job-search-form h2 {
    font-size: 35px;
    margin-bottom: 10px;
  }

  .fullwidth .header-nav .nav > li,
  .fullwidth .is-fixed .header-nav .nav > li {
    padding: 0;
  }

  .fullwidth .header-nav .nav > li > a {
    border-radius: 0;
  }

  .nav.navbar-nav li:hover a i.fa-chevron-down {
    color: #fff;
  }

  .candidates-are-sys {
    margin-top: 30px;
  }

  .extra-nav {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .blog-post.blog-md .dez-post-media {
    width: 100%;
  }

  .blog-post.blog-md .dez-post-info {
    display: block;
    padding: 20px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .job-search-form .form-control,
  .job-search-form .input-group-prepend .site-button {
    height: 40px;
    border-radius: 0px !important;
    margin: 0;
    font-size: 13px;
  }

  .job-search-form .form-control:-moz-placeholder {
    font-size: 13px;
  }

  .featurd-job-list .job-box-list {
    display: block;
  }

  .featurd-job-list .job-title,
  .featurd-job-list .job-info-box {
    display: block;
    width: 100%;
    float: none;
    text-align: left;
  }

  .job-info {
    margin-top: 20px;
  }

  .featurd-job-list .job-company-logo {
    width: auto;
    text-align: left;
  }

  .job-info ul {
    text-align: left;
  }

  .job-info li {
    margin-right: 10px;
  }

  .extra-nav {
    display: block;
  }

  .find-job-bx h2 {
    font-size: 24px;
    line-height: 33px;
  }

  .find-job-bx form {
    padding: 30px 30px 30px 30px;
  }

  .head-counter {
    display: block !important;
  }

  .head-counter-bx {
    margin-left: 0;
    display: inline-block;
    margin-right: 0;
    width: 33.33%;
    float: left;
  }

  .head-counter-bx h2 {
    font-size: 24px;
  }

  .head-counter-bx h6 {
    font-size: 14px;
    font-weight: 400;
  }

  .blog-post.blog-md .dez-post-info {
    padding: 0;
  }
}

@media only screen and (max-width: 576px) {
  .job-search-form h2 {
    font-size: 30px;
    margin-bottom: 5px;
    line-height: 40px;
  }

  .job-search-form h3 {
    font-size: 18px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 28px;
  }

  .post-job-bx .d-flex {
    display: block !important;
  }

  .job-post-company {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .job-time {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 360px) {
  .featurd-job-list.all-resume .job-company-logo {
    display: none;
  }
}

.browse-job .search-order form .input-group {
  margin-bottom: 40px;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
}

.browse-job .search-order form .input-group .form-control {
  margin-right: 0px;
  box-shadow: none !important;
}

.browse-job .search-order form .input-group .input-group-text {
  background: #fff;
  border: 0px;
}

.job-links {
  position: absolute;
  top: 25px;
  right: 25px;
}

.job-links i {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid #2e55fa;
  line-height: 30px;
  text-align: center;
  margin-left: 5px;
}

.candidate-info .candidate-detail {
  padding: 30px 0px;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
}

.canditate-des {
  position: relative;
  display: inline-block;
  border: 2px solid #2e55fa;
  outline-offset: 3px;
  border-radius: 100%;
  width: 150px;
  height: 150px;
}

.canditate-des .upload-link {
  position: absolute;
  width: 45px;
  height: 45px;
  line-height: 45px;
  background: #fff;
  top: 0;
  right: 0;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
  border-radius: 100%;
  color: #2e55fa;
  overflow: hidden;
}

.candidate-info img {
  border-radius: 100%;
  width: 100%;
  height: 146px;
  background-color: #fff;
  padding: 5px;
}

.candidate-title {
  margin-top: 25px;
}

.candidate-info ul {
  list-style: none;
  margin-bottom: 0px;
}

.candidate-info ul li a {
  width: 100%;
  padding: 15px 20px;
  display: inline-block;
  border-top: 1px solid #e1e7ff;
  color: #232323;
  border-left: 3px solid rgba(0, 0, 0, 0);
}

.candidate-info ul li a i {
  margin-right: 5px;
}

.candidate-info ul li a:hover,
.candidate-info ul li a.active {
  background: #60ba5d6b;
  border-left-color: #60ba5d;
}

.about-us-area.about-us-area-style-two h3 {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  top: auto;
  bottom: 50px;
  right: auto;
  left: 0;
}

.about-img-content h3 {
  font-size: 25px;
  max-width: 200px;
  position: absolute;
  top: 60px;
  right: -66px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  margin-bottom: 0;
}

.candidate-info ul li a span {
  transition: all 0.5s;
}

.about-content ul li:last-child {
  margin-bottom: 0;
}

.about-content ul li {
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 16px;
  position: relative;
  padding-left: 20px;
}

.about-content ul li::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: #62bd5e;
  border-radius: 50%;
}

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background:  url(../images/loader.gif) 50% 50% no-repeat rgba(225, 225, 225, 0.5);
  opacity: 1;
}
.PhoneInputInput {
  height: 50px !important;
  border-radius: 5px !important;
  margin-right: 25px !important;
  background-color: #fff !important;
  border: 0 !important;
  box-shadow: 0 0 10px 0 rgb(0 24 128 / 10%) !important;
}
.candidate-info ul li a:hover span {
  margin-left: 10px;
  transition: all 0.5s;
}

.job-post-info .posted-info {
  background-color: #e1e7ff;
  padding: 10px;
}

.browse-job-title .widget-title {
  margin: 10px 0;
}

.upload-link:hover {
  cursor: pointer;
}

.tooltip {
  border-radius: 5px;
  font-size: 13px;
  text-transform: capitalize;
  font-family: roboto;
  z-index: 99;
}

.update-flie {
  position: absolute;
  opacity: 0;
  z-index: 0;
  width: 100px;
  cursor: pointer;
  left: 0;
}

.canditate-des::before {
  content: "";
  position: absolute;
  background-color: #2e55fa;
  height: 115%;
  width: 2px;
  left: 50%;
  top: -10px;
  z-index: -1;
}

.canditate-des {
  background-color: #fff;
  z-index: 99;
}

.canditate-des::after {
  content: "";
  position: absolute;
  left: -15px;
  top: 50%;
  width: 120%;
  background-color: #2e55fa;
  height: 2px;
  z-index: -1;
}

.browse-job-sort .btn.dropdown-toggle.btn-default {
  width: 150px;
  float: right;
}

/* Alerts Job */
.table-job-bx thead th,
.table-job-bx tbody td {
  padding: 15px 20px;
}

.table-job-bx .job-links {
  padding: 20px 20px 20px 5px;
}

.job-bx {
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
  padding: 20px 30px;
}

.table-job-bx .job-name {
  font-weight: 600;
  color: #333333;
  font-size: 14px;
  font-family: "roboto", sans-serif;
}

.table-job-bx .criterias {
  font-size: 13px;
  font-family: roboto;
}

.table-job-bx .job-links {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
}

.table-job-bx thead th {
  background-color: #e1e7ff;
}

.table-job-bx tbody tr {
  border-bottom: 1px solid #e1e7ff;
}

.table-job-bx tbody tr:nth-child(2n + 2) {
  background-color: #f9f9f9;
}

/* CV Manager */
.cv-manager > li {
  clear: both;
  position: relative;
  display: flex;
  border-bottom: 1px solid #e1e7ff;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

/*  Job Profile */
.job-profile textarea.form-control {
  height: 150px;
  line-height: 24px;
}

.job-profile label,
.submit-resume label,
.browse-job label {
  color: #333;
}

.job-bx-title {
  border-bottom: 1px solid #e1e7ff;
  padding: 10px 0;
  font-size: 22px;
  margin-bottom: 30px;
}

.custom-file > input[type="file"] {
  cursor: pointer;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}

.custom-file {
  overflow: hidden;
  position: relative;
  padding: 12px;
  text-align: center;
  border: 0 !important;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1) !important;
  height: 50px;
}

.save-job .job-post-company span {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  float: left;
}

/* Jobs Link */
.job-links .fa-download,
.job-links .fa-eye {
  background-color: #2e55fa;
  color: #fff;
  border: 0;
  border-radius: 5px;
  padding: 7px;
  line-height: 16px;
}

.job-links .ti-trash {
  background-color: #e53232;
  color: #fff;
  border: 0;
  border-radius: 5px;
  padding: 7px;
}

.pencil .fa-pencil {
  background-color: #2e55fa;
  color: #fff;
  border: 0;
  border-radius: 5px;
  padding: 7px;
  line-height: 16px;
}

/* Modal Box Info */
.modal-bx-info {
  z-index: 999999;
}

.modal-backdrop {
  z-index: 99999;
}

.modal-bx-info .modal-header .close {
  opacity: 1;
  font-size: 36px;
}

.modal-bx-info .modal-header {
  background-color: rgba(0, 0, 0, 0.04);
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 20px;
  overflow: hidden;
}

.modal-bx-info .modal-header .modal-title {
  color: #333;
  margin-left: 20px;
  width: 100%;
  font-weight: 700;
  font-family: nunito;
  font-size: 18px;
  align-self: center;
}

.modal-bx-info .modal-header .logo-img {
  width: 60px;
}

.modal-bx-info .modal-body li {
  list-style: none;
  padding: 5px 0;
  display: block;
  clear: both;
}

.modal-bx-info .modal-body li strong {
  color: #333;
  font-size: 16px;
  font-family: nunito;
  font-weight: 600;
  display: flex;
  float: left;
  margin-right: 20px;
  width: 22%;
}

.modal-bx-info .modal-body li p {
  display: flex;
  margin-bottom: 0;
}

.modal-bx-info .modal-body {
  background-color: rgba(0, 0, 0, 0.01);
}

.modal-bx-info .modal-content {
  border-radius: 5px;
  border: 0;
  overflow: hidden;
}

.modal-bx-info .modal-header .close {
  border-left: 1px solid #e0e0e0;
  padding: 26px 20px;
}

.modal-bx-info .modal-footer {
  border-color: #e0e0e0;
  background-color: rgba(0, 0, 0, 0.04);
}

.job-bx-title .btn-group.bootstrap-select {
  width: 120px !important;
  float: right;
}

.job-bx-title .btn.dropdown-toggle.btn-default {
  box-shadow: unset !important;
  border: 1px solid #e1e7ff !important;
  width: 120px;
  height: 40px !important;
  float: right;
  border-radius: 5px;
  font-family: roboto;
  font-size: 12px;
  padding: 12px;
}

.job-post-company {
  margin-right: 20px;
}

/* Browse Job */
.browse-job-find .find-job-bx {
  text-align: left;
  position: relative;
  top: -50px;
  background-color: #fff;
  border-radius: 5px;
}

.post-job-bx.browse-job-grid {
  margin: 0 -15px;
}

.post-job-bx.browse-job-grid > li {
  padding: 0 15px;
}

.post-job-bx.browse-job-grid .salary-bx span {
  font-size: 18px;
}

/* Company Info */
.company-info .update {
  right: -100px;
}

/* Transaction Table */
.amount,
.transfer,
.order-id,
.expired,
.application,
.table-job-bx .date {
  font-size: 14px;
  font-family: roboto;
}

.table-job-bx th {
  font-size: 16px;
}

.amount {
  font-weight: 700;
}

.expired.pending {
  color: #ed527b;
}

.expired.success {
  color: #25ad60;
}

.expired,
.expired.pending,
.expired.success {
  font-weight: 500;
}

/* Company Manage Job */
.company-manage-job .job-name a {
  font-size: 16px;
  color: #333;
}

.company-manage-job .job-post-info li {
  font-weight: 400;
}

/*  Login 2 */
.login-style2 {
  position: relative;
  min-height: 100vh;
  padding-bottom: 100px;
  width: 100%;
  display: flex;
  align-items: center;
}

.login-style2 .section-full {
  width: 100%;
}

.login-style2 .logo {
  margin-bottom: 30px;
  width: 180px;
}

.login-2 p,
.login-2 .site-button-link {
  font-family: roboto;
}

.login-2 .site-button-link i {
  margin: 0;
}

.login-2 {
  background-color: #fff;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  width: 400px;
  float: right;
  margin: 0;
  border-radius: 3px;
}

.login-footer .container {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px 0;
}

.login-footer {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}

.login-bx {
  padding-bottom: 0;
}

.login-form-bx .box-skew,
.login-form-bx .box-skew1 {
  height: 100vh;
  min-height: 100vh;
  position: relative;
}

.login-form-bx .box-skew1:after {
  content: "";
  background-image: url(../images/background/bg6.jpg);
  height: 100%;
  position: absolute;
  width: 120%;
  left: -30px;
  top: 0;
  background-size: cover;
  z-index: -1;
  transform: skew(-5deg);
}

.login-form-bx .box-skew1 {
  z-index: 1;
  background-color: #fff;
}

.login-form-bx .box-skew .login-2 {
  box-shadow: none;
  position: absolute;
  z-index: 1;
  right: 20px;
}

.login-form-bx .box-skew .login-2:after {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  content: "";
  height: 100%;
  width: 200%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.login-form-bx .login-footer .container {
  z-index: 99;
  position: relative;
  border: 0;
}

.login-form-bx {
  padding-bottom: 0;
}

.login-style3 .login-form {
  margin: 0 40px 0 50px;
}

.login-style3 .skew-section {
  padding-left: 0;
  height: 100vh;
  display: flex;
  align-items: center;
}

.login-style3 .form-group.text-left span {
  display: -webkit-inline-box;
}

.login-style3 .mCustomScrollBox {
  width: 100%;
  height: auto;
}

.sticky-top {
  z-index: 99;
}

.text-black-light {
  color: #999;
}

.browse-job.login-style3 .form-control,
.browse-job.login-style2 .form-control {
  margin-right: 0;
}

.site-button.outline.active {
  color: #fff;
}

.job-time span {
  margin-bottom: 5px;
}

.dropdown-menu.open.show {
  z-index: 9;
}

/* Hover Effect */
.table-job-bx .job-name a:hover,
.browse-job-grid a:hover,
.post-job-bx .job-post-info li a:hover,
.cv-manager .job-post-info a:hover,
.browse-job .job-post-info a:hover,
.candidate-title a:hover,
.save-job .criterias a:hover,
.login-form .forget-pass:hover,
.login-form label a:hover,
.login-2 .forget-pass:hover,
.forget-pass:hover,
.category-list li a:hover {
  text-decoration: underline;
  color: #333;
}

.icon-bx-wraper .icon-content .dez-tilte:hover {
  text-decoration: underline;
}

.browse-job .posted-info a:hover {
  text-decoration: none;
  color: #fff;
}

.login-form .forget-pass {
  display: block;
  margin-top: 20px;
  text-align: center;
}

.login-form .nav {
  display: unset;
}

.login-form .site-button.button-md.btn-block {
  font-weight: 700;
}

.table-job-bx .feature {
  padding-right: 0;
}

.table-job-bx .job-name a,
.pricingtable-wrapper.style2 .site-button.white:hover span {
  color: #333;
}

.feature .custom-control .custom-control-label::before {
  border: 2px solid #2e55fa;
}

/* LIKE BTN ===================== */
/* The like-btn */
.like-btn {
  display: block;
  position: absolute;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: FontAwesome;
  top: 25px;
  right: 25px;
}

.like-btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border: 1px solid #2e55fa;
  border-radius: 20px;
}

.like-btn input:checked ~ .checkmark {
  background-color: #2196f3;
}

.checkmark:after {
  content: "\f08a";
  position: absolute;
  display: none;
}

.like-btn input:checked ~ .checkmark:before {
  width: 100%;
  height: 100%;
  background-color: #2e55fa;
  left: 0;
  top: 0;
  border-radius: 20px;
  color: #fff;
  padding: 3px 6px;
}

.like-btn .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
}

.like-btn .checkmark:before {
  content: "\f08a";
  left: 6px;
  top: 3px;
  position: absolute;
  font-size: 16px;
  font-weight: normal;
  color: #2e55fa;
}

.comment-reply-link:hover {
  color: #333 !important;
}

.job-post-info.m-a0 {
  margin: 0 !important;
}

.right-arrow {
  background-image: url(../images/sap.png);
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  padding-left: 25px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.post-bx .job-post-info h5 {
  margin-right: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (max-width: 1024px) {
  .job-bx {
    overflow: scroll;
  }

  .cv-manager .action-bx {
    right: 5px;
  }

  .header-nav .nav > li .sub-menu li > .sub-menu.left,
  .header-nav .nav > li .sub-menu li:hover .sub-menu.left {
    left: auto;
    right: 219px;
  }
}

@media only screen and (max-width: 768px) {
  .login-2 {
    width: 100%;
  }

  .table-job-bx table {
    width: 700px;
  }
}

@media only screen and (max-width: 600px) {
  .company-manage-job {
    min-width: 200%;
  }

  .login-style2 .max-w400.align-self-center {
    padding: 30px;
    max-width: 100%;
    text-align: center;
  }

  .login-style2 .logo {
    margin: 0 auto 30px;
  }

  .login-form-bx .box-skew {
    height: auto;
    min-height: auto;
    margin: 30px 10px -50px;
    z-index: 9;
  }

  .login-form-bx .box-skew .login-2 {
    position: relative;
    right: 0;
  }

  .login-form-bx .box-skew1 {
    min-height: 70vh;
    height: 70vh;
  }

  .login-form-bx .box-skew .login-2 .tab-content,
  .login-form-bx .box-skew .login-2::after {
    width: 100%;
  }

  .login-style2 .login-footer .text-black {
    color: #fff;
  }

  .login-style3 .tab-content .tab-pane {
    width: 100%;
  }

  .table-job-bx table {
    width: 800px;
  }
}

@media only screen and (max-width: 360px) {
  .job-bx-title .btn-group.bootstrap-select,
  .job-bx-title .btn.dropdown-toggle.btn-default {
    width: 120px !important;
  }

  .cv-manager > li {
    display: inline-block;
  }

  .cv-manager > li .job-post-company {
    margin-right: 15px;
  }

  .cv-manager .action-bx {
    position: unset;
  }

  .modal-bx-info .modal-body li strong {
    float: unset;
    width: 100%;
  }

  .company-manage-job {
    min-width: 300%;
  }

  .login-style2 .login-footer .float-left,
  .login-style2 .login-footer .float-right {
    text-align: center;
    float: unset !important;
    display: block;
  }

  .login-style3 .login-form {
    margin: 0 30px;
  }

  .table-job-bx table {
    width: 700px;
  }
}

/* Lead Form Modal */
.lead-form-modal {
  z-index: 999999;
}

.lead-form-modal .modal-body {
  padding: 0;
}

.lead-form-modal .modal-content,
.lead-form-modal .modal-dialog {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  min-width: 60%;
  border-radius: 5px;
  overflow: hidden;
}

.lead-form-modal .modal-body {
  background-color: rgba(0, 0, 0, 0);
  z-index: 2;
  position: relative;
}

.lead-form-modal .lead-form {
  background-color: #eef1f2;
  padding: 60px 30px;
  text-align: center;
}

.lead-form-modal .form-group {
  margin-bottom: 15px;
}

.lead-form-modal .close {
  font-family: raleway;
  font-size: 45px;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  color: #333;
  z-index: 9;
  font-weight: 200;
  padding: 10px 15px;
}

.modal-backdrop.in {
  opacity: 0.8;
}

.lead-form-modal .form-info {
  position: relative;
  z-index: 99;
  padding: 60px 30px;
}

/* Attach Resume */
.attach-resume .custom-file,
.attach-resume .custom-file .form-control {
  height: 150px;
  display: flex;
}

.list-row .list-line p {
  font-family: "Lato", sans-serif;
}

.list-row .list-line {
  list-style: none;
  border-bottom: 1px solid #e1e7ff;
  padding: 20px 0;
  width: 100%;
}

.add-btn {
  margin-left: auto;
  align-self: center;
  max-width: 100%;
  margin-bottom: auto;
}

.list-row .list-line:last-child {
  padding-bottom: 0;
  border: 0;
}

.modal-bx-info.editor .modal-title {
  margin: 0;
}

.modal-bx-info.editor .modal-dialog {
  max-width: 600px;
}

.modal-bx-info.editor textarea {
  height: 120px;
}

.tag_complete .tags_container .tag .close::after {
  background: #bcbcbc;
  line-height: 22px;
}

.custom-control-label {
  cursor: pointer;
}

/* Profile Edit */
.profile-edit .canditate-des {
  border: 0;
  width: 100px;
  height: 100px;
  margin: 0 30px 30px 0;
  background-color: unset;
}

.profile-edit .canditate-des .upload-link {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
}

.profile-edit .canditate-des:after,
.profile-edit .canditate-des:before {
  content: unset;
}

.profile-edit .candidate-info .candidate-detail {
  box-shadow: unset;
  font-family: roboto;
  font-weight: 400;
  display: flex;
  padding-top: 0;
}

.profile-edit .candidate-info ul li i {
  margin-right: 10px;
}

.profile-edit .candidate-info ul li {
  font-size: 15px;
  width: 50%;
  font-weight: 400;
  float: left;
  margin-bottom: 10px;
}

.profile-edit li {
  list-style: none;
}

.profile-edit .list-check {
  margin: 0;
}

.profile-edit .list-check li {
  padding: 2px 5px 2px 25px;
}

.profile-edit .list-check li::before {
  font-size: 12px;
}

/* pending-info */
.pending-info {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: rgba(46, 85, 250, 0.8);
}

/* Progress Box */
.progress-box .progress {
  height: 3px;
}

.progress-box .progress-info {
  text-align: left;
  font-size: 14px;
  margin-bottom: 5px;
}

.progress-box .progress-info span {
  float: right;
}

/* Category Info */
.category-jobs-info {
  bottom: 50px;
  position: absolute;
}

.category-jobs-info .nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.category-jobs-info .nav ul li {
  display: inline-block;
  float: left;
}

.category-jobs-info .nav ul li a {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  display: block;
  padding: 10px 15px;
  font-family: roboto;
  font-size: 14px;
  font-weight: 400;
  background-color: rgba(25, 31, 35, 0.7);
}

.category-jobs-info .nav ul li:last-child a {
  border-right: 0;
}

/* category-list */
.category-list li {
  list-style: none;
  margin-bottom: 10px;
}

.category-bx li a {
  background-color: #fbfbfb;
  border: 1px solid #bfdcff;
  padding: 5px 10px 5px 0;
  border-radius: 5px;
  clear: both;
  display: -webkit-flex;
}

.category-bx li a .logo,
.category-bx li a span {
  align-self: center;
}

.category-bx li a img {
  width: 50px;
  border-right: 1px solid #bfdcff;
  margin-right: 10px;
  padding: 4px 10px;
  display: flex;
}

.site-header.border-bottom .main-bar-wraper {
  border-bottom: 2px solid #2e55fa;
}

/* Company logo wg  */
.company-logo-wg li {
  border: 1px solid #ebebeb;
  display: inline-block;
  float: left;
  line-height: 100px;
  margin: 0 -1px -1px 0;
  width: 20%;
  padding: 10px;
}

.company-logo-wg {
  list-style: none;
  padding: 0;
}

.company-logo-wg.sidebar li a {
  align-self: center;
}

.company-logo-wg.sidebar li {
  width: 33.6%;
  display: flex;
  height: 80px;
  padding: 0;
}

.job-bx .site-filters a {
  padding: 6px 15px;
  font-size: 14px;
}

/* Sidebar Filter */
.sidebar-filter {
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
}

.sidebar-filter .title {
  font-size: 16px;
  padding: 10px 15px;
  margin: 0;
}

.sidebar-filter .acod-head .acod-title a,
.sidebar-filter .acod-head .acod-title .collapsed {
  padding: 10px 15px;
  border-width: 1px 0;
  border-radius: 0;
}

.sidebar-filter .panel {
  margin-bottom: -1px;
}

.sidebar-filter .acod-head .acod-title {
  font-size: 16px;
  margin-bottom: -1px;
}

.sidebar-filter .acod-head a.collapsed::after,
.sidebar-filter .acod-head a::after {
  font-size: 12px;
}

.sidebar-filter .custom-control label {
  font-weight: 400;
  font-family: roboto;
}

.sidebar-filter .custom-control label span {
  font-size: 14px;
  color: #909090;
}

.select-title {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  line-height: 40px;
  margin-right: 15px;
}

/* Candidates Profile */
.job-bx .dez-post-meta ul {
  margin: 0;
}

.skill-profile {
  height: 100%;
  width: 60px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 20px;
}

.jobs-category-bx .job-bx {
  padding: 10px 20px;
}

.jobs-category-bx .job-bx .job-bx-title {
  margin-bottom: 20px;
  padding-bottom: 5px;
}

.jobs-category-bx .job-bx .category-list {
  margin-bottom: 15px;
}

.recaptcha-bx {
  margin-bottom: 20px;
}

.job-alert-bx {
  padding: 10px 10px 10px 0px;
}

@media only screen and (max-width: 1024px) {
  .lead-form-modal .modal-content,
  .lead-form-modal .modal-dialog {
    min-width: 80%;
  }

  .header-nav .nav > li .sub-menu.left {
    left: auto;
  }
}

@media only screen and (max-width: 768px) {
  .lead-form-modal .modal-content,
  .lead-form-modal .modal-dialog {
    min-width: 90%;
  }

  .profile-edit .candidate-info ul li {
    width: 100%;
  }

  .category-jobs-info {
    bottom: 20px;
  }

  .job-search-form {
    bottom: 20px;
    position: relative;
  }

  .recaptcha-bx {
    margin-bottom: 5px;
  }

  .job-alert-bx {
    padding: 10px 10px 25px 10px;
  }
}

@media only screen and (max-width: 600px) {
  .modal-bx-info.editor .modal-dialog {
    max-width: 540px;
    margin-top: 30px !important;
  }

  .company-logo-wg li {
    width: 33.33%;
  }

  .company-logo-wg.sidebar li {
    padding: 35px;
    width: 33.33%;
  }

  .post-resume {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 360px) {
  .profile-edit .candidate-info .candidate-detail {
    display: block;
  }

  .profile-edit .candidate-info ul li {
    width: 100%;
  }

  .company-logo-wg li {
    width: 50%;
  }

  .category-jobs-info .nav ul li a {
    font-size: 12px;
    padding: 10px 12px !important;
  }

  .job-alert-bx {
    padding: 0 0 25px 0;
  }

  .list-row .list-line h6,
  .job-bx .d-flex h5 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 200px;
  }
}

.btn-suport {
  right: 0;
  bottom: 50%;
  position: fixed;
  z-index: 99;
  box-shadow: 0px 0px 15px 1px rgba(81, 77, 92, 0.2);
  padding: 3px;
  border-radius: 5px 0 0 5px;
  background-color: #fff;
}

.btn-suport a {
  color: #c1bfd0;
  margin: 0;
  padding: 3px 5px;
  text-align: center;
  display: block;
  font-size: 12px;
  font-weight: 700;
}

.btn-suport a span {
  position: absolute;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  outline: none;
}

.browse-job .job-bx-title .float-right .custom-btn {
  box-shadow: unset !important;
  border: 1px solid #e1e7ff !important;
  width: 120px;
  height: 40px !important;
  float: right;
  border-radius: 5px;
  font-family: roboto;
  font-size: 12px;
  padding: 12px;
  color: #495057;
}

.submit-resume .form-group .custom-select {
  height: 50px;
  background-color: #fff !important;
  border: 0 !important;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1) !important;
}

.browse-candidates .clearfix .input-group .custom-btn {
  height: 50px;
  background-color: #fff !important;
  border: 0 !important;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1) !important;
}

.dezPlaceAni .form-group .select-btn {
  padding: 0;
  height: 50px;
  color: #000;
  font-size: 16px;
  border-radius: 0;
  background-color: transparent;
  border-width: 0 0 2px 0;
  border-color: #2e55fa;
}

.site-filters.style1 .nav-item .nav-link.active {
  background-color: transparent;
  color: #2e55fa;
}

.h-35rem {
  height: 35rem !important;
}

.scroll {
  overflow-x: hidden;
  /* overflow-y: scroll; */
  height: 12rem;
}

/* width */
.scroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 2rem;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
  background: #1d46f5;
  border-radius: 2rem;
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
  background: #1d46f5;
}

/*search box css start here*/
.search-sec {
  padding: 2rem;
}

.search-slt {
  display: block;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  height: calc(3rem + 2px) !important;
  border-radius: 0;
}

.wrn-btn {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  height: calc(3rem + 2px) !important;
  border-radius: 0;
}

@media (min-width: 992px) {
  .search-sec {
    position: absolute;
    /* top: -35rem; */
    bottom: 60px;
    width: 100%;
  }
}

@media (max-width: 992px) {
  .search-sec {
  }
}

.text-style {
  line-height: 1.5;
  font-size: 26px;
  font-family: serif;
}

.box-shadow {
  box-shadow: -4px 2px 9px 6px grey;
}

ul {
  list-style: none;
}

.t-body {
  font-size: 18px;
  line-height: 26px;
}

.rec {
  background-color: #dd3434;
  color: white;
  animation-iteration-count: infinite;
  animation-name: rotate;
  animation-timing-function: linear;
  animation-duration: 5s;
  padding-top: 3px;
}

@keyframes rotate {
  0% {
    transform: rotateY(0deg);
  }

  25% {
    transform: rotateY(90deg);
  }

  50% {
    transform: rotateY(180deg);
  }

  75% {
    transform: rotateY(270deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.radio-control {
  position: relative;
  top: 9px;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  left: 7px;
}

.border-radius {
  border-radius: 1.25rem !important;
}

.border-hover:hover,
.border-hover:focus,
.border-hover:active {
  border: 1px solid #43a34375 !important;
  color: lightgreen !important;
}

.isActive {
  background-color: #2e55fa !important;
  color: #fff;
  border: 1px solid #1681f1 !important;
}

input[type="radio"]#Yellow {
  height: 16px;
  accent-color: blue;
  width: 3rem;
}

.rounded-1rem {
  border-radius: 1rem !important;
}
